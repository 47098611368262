import "./App.css";
import Home from "./component/Home/Home";
import Navs from "./component/Navs/Navs";

function App() {
  return (
    <>
      <Navs />
      <Home />
    </>
  );
}

export default App;
